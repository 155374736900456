<template>
  <Component
    :is="component"
    :to="to"
    :hreflang="to ? 'de' : null"
    class="group inline-block"
  >
    <span
      class="hover-underline-animation focus-default whitespace-pre-wrap rounded-none group-focus-visible:rounded-[1em] group-focus-visible:ring-2"
    >
      <slot />
    </span>
  </Component>
</template>

<script setup>
const props = defineProps({
  to: { type: [String, Object], default: null },
  is: { type: String, default: 'div' },
})

// determine whether to load NuxtLink or div
const component = computed(() => {
  if (props.to) {
    return resolveComponent('NuxtLink')
  }
  return props.is
})
</script>
